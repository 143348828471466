import { DialogComponent } from "@syncfusion/ej2-react-popups";
import DOMPurify from "dompurify";
import React, {
  PropsWithChildren,
  Reducer,
  useEffect,
  useReducer,
  useRef,
} from "react";
import { useSetRecoilState } from "recoil";
import { PreventFocusOnDialogOpen } from "../../../../RAFComponents/Dialog/SFDialogUtils";
import CustomCardWidget from "../../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDetailFieldCustom from "../../../../RAFComponents/Navigation/RAFDetailFieldCustom";
import RAFDetailsValueWithSeparator from "../../../../RAFComponents/Navigation/RAFDetailsValueWithSeparator/RAFDetailsValueWithSeparator";
import {
  IsSuperAdminQueryString,
  RafClearDialogContent,
} from "../../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../../RAFComponents/helpers/PermissionHelper";
import { getDisplayNameByModuleName } from "../../../../RAFComponents/helpers/RAFMenuHelper";
import RAFPermissionsRender from "../../../../RAFComponents/helpers/RAFPermissionsRender";
import {
  ConvertSystemName,
  IsNotNullOrWhiteSpace,
  isNotNullAndUndefined,
  joinStringArray,
} from "../../../../RAFComponents/helpers/utils";
import { RAFDataType } from "../../../../RAFComponents/models/Common/RAFDataType";
import { EntityRow } from "../../../../RAFMaster/RMModules/Entity/EntityRow";
import { getEntityByObjectName } from "../../../../RAFMaster/helpers/RMutils";
import ACLoadingPanel from "../../../../components/shared/ACLoadingPanel";
import {
  CarePlanPermissionConstants,
  CareRecipientPermissionConstants,
  ServiceTransactionPermissionConstants,
} from "../../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity, CareEsioTab } from "../../../../constants/CareESIO/CareEsioConstants";
import {
  BrowserIsDevice,
  MomentFormats,
  RAFButtonConstant,
  RAFRiskRegisterType,
  RAFTaskType,
} from "../../../../constants/Common/Constants";
import { RAFEntityName } from "../../../../constants/Common/EntityConstants";
import { PermissionConstants } from "../../../../constants/Common/PermissionConstants";
import ServiceTimesheetSummary from "../../../ActiveContacts/ServiceTransaction/ServiceTimesheetSummary/ServiceTimesheetSummary";
import ServiceTransactionSummary from "../../../ActiveContacts/ServiceTransaction/ServiceTransactionSummary/ServiceTransactionSummary";
import { ShiftSummaryDetailsArray } from "../../../ActiveContacts/ServiceTransaction/ServiceTransactionSummary/ServiceTransactionSummaryHelper";
import RAFCreate from "../../../Common/Create";
import RAFEdit from "../../../Common/Update";
import CareInterventionSupportList from "../../CareInterventionSupport/CareInterventionSupportList";
import { getActiveCarePlanByCareRecipient } from "../../CarePlan/CarePlanHelper";
import { atomSelectedRecipientRightContent } from "../../CareRecipientHelper";
import ClientProfileNoteList from "../../CareShiftLog/ClientNote/ClientProfileNoteList";
import MedicationList from "../../Medication/MedicationList";
import CarePreferenceList from "../../Preference/CarePreferenceList";
import HealthConditionList from "../../RiskRegister/HealthConditionList";
import RiskRegisterList from "../../RiskRegister/RiskRegisterList";
import TriggerList from "../../Trigger/TriggerList";

interface IProps {
  relatedToType: string;
  currentObject: any;
  careRecipientName: string;
  careRecipientUID: string;
  paddingClass?: string;
  showTimeSheetAndShiftSummary?: boolean;
  showClientNotesContent?: boolean;
  onServiceTransactionClicked?: (
    selectedItem: ShiftSummaryDetailsArray
  ) => void;
  onTabSelected?: (item, defaultSelectedTaskType: string) => void;
}

interface IState {
  showKeyCareDetails: boolean;
  showHealthConditionDetails: boolean;
  showPersonalityDetails: boolean;
  isLoading: boolean;
  carePlanEntity: EntityRow;
  activeCarePlan: any;
  showManageCarePlanContent: boolean;
}

function CareProfileContentDetails({
  showTimeSheetAndShiftSummary = true,
  showClientNotesContent = true,
  currentObject,
  relatedToType = CareEsioEntity.CareRecipient.EntityName,
  ...props
}: PropsWithChildren<IProps>) {
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      showKeyCareDetails: false,
      showHealthConditionDetails: false,
      showPersonalityDetails: false,
      isLoading: true,
      carePlanEntity: null,
      activeCarePlan: null,
      showManageCarePlanContent: false,
    }
  );
  let keyCareDetailsDialogRef = useRef<DialogComponent>(null);
  let healthconditionDetailsDialogRef = useRef<DialogComponent>(null);
  let personalityDetailsDialogRef = useRef<DialogComponent>(null);
  const setSelectedRecipientRightContent = useSetRecoilState(
    atomSelectedRecipientRightContent(relatedToType)
  );
  let carePlanDialogRef = useRef<DialogComponent>(null);
  const carePlanModule = CareEsioEntity.CarePlan.EntityName;
  const isAdmin = IsSuperAdminQueryString();

  useEffect(() => {
    refresh();
  }, [props.careRecipientUID]);

  const refresh = async () => {
    await RafClearDialogContent(carePlanDialogRef);
    if (isNotNullAndUndefined(props.careRecipientUID)) {
      setState({ isLoading: true, showManageCarePlanContent: false });
      const carePlanEntity = await getEntityByObjectName({
        ObjectName: ConvertSystemName(carePlanModule),
      });
      const activeCarePlan = await getActiveCarePlanByCareRecipient(
        props.careRecipientUID,
        isNotNullAndUndefined(carePlanEntity) ? carePlanEntity.UID : null
      );
      setState({ isLoading: false, activeCarePlan, carePlanEntity });
    }
  };

  const onSelectedRecipientRightContent = (item?) => {
    if (isNotNullAndUndefined(item)) {
      setSelectedRecipientRightContent(item);
    } else {
      setSelectedRecipientRightContent(null);
    }
  };

  const onTabSelected = (item, defaultSelectedTaskType: string) => {
    if (isNotNullAndUndefined(props.onTabSelected)) {
      props.onTabSelected(item, defaultSelectedTaskType);
    }
  };

  const onClickCarePlanSummaryCard = (selectedItem) => {
    // onSelectedRecipientRightContent(
    //   CareEsioEntity.CarePlan.EntityName
    // )
    // setselectedCarePlanTabItem(selectedItem)
  };

  const healthConditionContent = (uiStyle?: "SummaryView" | "DetailView") => {
    return (
      <div
        className={
          uiStyle === "SummaryView" ? "section__container pointer" : ""
        }
      >
        {/* {uiStyle === "SummaryView" && (
          <div className="col-12 mb-1">
            <span className="subtitle_3 mb-2">MEDICAL HISTORY</span>
          </div>
        )}
        {uiStyle === "SummaryView" ? (
          <CustomCardWidget cardClassName="light_border section__div profile_card_custom_card customScrollBar"
          >
            {isNotNullAndUndefined(currentObject.MedicalHistory) && (
              <div className="col-12 mb-3">
                <span
                  className={"body_2 ecllipseSecondLine description-text"}
                  dangerouslySetInnerHTML={{
                    __html:
                      currentObject.MedicalHistory !== null
                        ? DOMPurify.sanitize(currentObject.MedicalHistory)
                        : "Not set",
                  }}
                ></span>
              </div>
            )}
            <div className="mb-2">
              <HealthConditionList
                careRecipientUID={props.careRecipientUID}
                careAssessmentUID={null}
                careAssessmentRow={null}
                uiStyle={"SummaryStyle"}
              />
            </div>

            <div>
              <MedicationList
                careRecipientUID={props.careRecipientUID}
                uiStyle={"SummaryStyle"}
              />
            </div>
          </CustomCardWidget>
        ) : ( */}
        <div>
          <div className="row gx-0 gy-3">
            {IsNotNullOrWhiteSpace(currentObject.medical_summary) && (
              <div className="col-12">
                <CustomCardWidget cardContentClassName="p-2">
                  <span
                    className={"body_2 description-text"}
                    dangerouslySetInnerHTML={{
                      __html:
                        currentObject.medical_summary !== null
                          ? DOMPurify.sanitize(currentObject.medical_summary)
                          : "Not set",
                    }}
                  ></span>
                </CustomCardWidget>
              </div>
            )}
            {(isNotNullAndUndefined(currentObject.allergies_intolerances) ||
              isNotNullAndUndefined(currentObject.medical_history)) && (
                <CustomCardWidget
                  cardContentClassName="p-2 p-md-3"
                  cardClassName="border-0"
                >
                  <div className="row gx-0 gy-3">
                    {isNotNullAndUndefined(
                      currentObject.allergies_intolerances
                    ) && (
                        <div className="col-12">
                          <RAFDetailFieldCustom
                            field="allergies_intolerances"
                            title="Allergies and Intolerances:"
                            value={currentObject.allergies_intolerances}
                            // valueDivClassName="col-md-7 col-xxl-9"
                            // labelDivClassName="col-md-5 col-xxl-3"
                            rowClassName="gy-2"
                            valueClassName="description-text details-value"
                            labelClassName="profile_card_details_label"
                          ></RAFDetailFieldCustom>
                        </div>
                      )}
                    {isNotNullAndUndefined(currentObject.medical_history) && (
                      <>
                        {isNotNullAndUndefined(
                          currentObject.allergies_intolerances
                        ) && (
                            <div className="col-12">
                              <div className="custom__card__separator custom__card__separator__light"></div>
                            </div>
                          )}
                        <div className="col-12">
                          <RAFDetailFieldCustom
                            field="medical_history"
                            title="Recent Medical Events:"
                            value={currentObject.medical_history}
                            // valueDivClassName="col-md-7 col-xxl-9"
                            // labelDivClassName="col-md-5 col-xxl-3"
                            rowClassName="gy-2"
                            valueClassName="description-text details-value"
                            labelClassName="profile_card_details_label"
                          ></RAFDetailFieldCustom>
                        </div>
                      </>
                    )}
                  </div>
                </CustomCardWidget>
              )}
            <div className="col-12">
              <div
                onClick={() => onClickCarePlanSummaryCard(
                  { text: CareEsioTab.PermanentCondition.CollectionName, value: CareEsioTab.PermanentCondition.Value }
                )}
              >
                <HealthConditionList
                  careRecipientUID={props.careRecipientUID}
                  careAssessmentUID={null}
                  careAssessmentRow={null}
                  uiStyle={
                    uiStyle === "SummaryView" ? "SummaryStyle" : "SummaryDetails"
                  }
                  showAddBtn={uiStyle === "SummaryView" ? false : true}
                  guttersClass="gy-2 g-0"
                  cardClassName="care_recipient_care_plan_item"
                />
              </div>
            </div>

            <div className="col-12">
              <div
                onClick={() => onClickCarePlanSummaryCard(
                  { text: CareEsioEntity.CareRecipientMedication.CollectionName, value: CareEsioEntity.CareRecipientMedication.EntityName }
                )}
              >
                <MedicationList
                  careRecipientUID={props.careRecipientUID}
                  // paddingClass="p-0"
                  uiStyle={
                    uiStyle === "SummaryView" ? "SummaryStyle" : "SummaryDetails"
                  }
                  // uiStyle={"SummaryDetails"}
                  showAddBtn={uiStyle === "SummaryView" ? false : true}
                  cardClassName="care_recipient_care_plan_item"
                />
              </div>
            </div>
          </div>
        </div>
        {/* )} */}
      </div>
    );
  };

  const keyCareDetailsContent = (uiStyle?: "SummaryView" | "DetailView") => {
    return (
      <div
        className={
          uiStyle === "SummaryView" ? "section__container pointer" : ""
        }
      >
        {/* {uiStyle === "SummaryView" && (
          <div className="col-12 mb-1">
            <span className="subtitle_3 mb-2">KEY CARE DETAILS</span>
          </div>
        )} */}
        {/* {uiStyle === "SummaryView" ? (
          <CustomCardWidget
            cardClassName="light_border section__div profile_card_custom_card customScrollBar">
            {isNotNullAndUndefined(currentObject.OtherPreferencesImpact) && (
              <div className="col-12 mb-3">
                <span
                  className={"body_2 ecllipseSecondLine description-text"}
                  dangerouslySetInnerHTML={{
                    __html:
                      currentObject.OtherPreferencesImpact !== null
                        ? DOMPurify.sanitize(
                          currentObject.OtherPreferencesImpact
                        )
                        : "Not set",
                  }}
                ></span>
              </div>
            )}
            <div>
              <RiskRegisterList
                careRecipientUID={props.careRecipientUID}
                careAssessmentUID={null}
                careAssessmentRow={null}
                uiStyle={
                  uiStyle === "SummaryView" ? "SummaryStyle" : "SummaryDetails"
                }
                riskType={RAFRiskRegisterType.Risk}
              />
            </div>
            <div className="mb-2">
              <TriggerList
                careRecipientUID={props.careRecipientUID}
                uiStyle="SummaryStyle"
              />
            </div>
            <div className="mb-2">
              <CareInterventionSupportList
                careRecipientUID={props.careRecipientUID}
                careAssessmentUID={null}
                careAssessmentRow={null}
                uiStyle={
                  uiStyle === "SummaryView" ? "SummaryStyle" : "SummaryDetails"
                }
                customCardClass="bg-light-blue-3"
                includeHeaderClass={false}
                showEmptyState={true}
              />
            </div>
          </CustomCardWidget>
        ) : ( */}
        <div>
          <div className="row gx-0 gy-3">
            {IsNotNullOrWhiteSpace(currentObject.OtherPreferencesImpact) && (
              <div className="col-12">
                <CustomCardWidget cardContentClassName="p-2 border-0">
                  <span
                    className={"body_2 description-text"}
                    dangerouslySetInnerHTML={{
                      __html:
                        currentObject.OtherPreferencesImpact !== null
                          ? DOMPurify.sanitize(
                            currentObject.OtherPreferencesImpact
                          )
                          : "Not set",
                    }}
                  ></span>
                </CustomCardWidget>
              </div>
            )}
            <div className="col-12">
              <div
                onClick={() => onClickCarePlanSummaryCard(
                  { text: CareEsioEntity.RiskRegister.CollectionName, value: CareEsioEntity.RiskRegister.EntityName }
                )}
              >
                <RiskRegisterList
                  careRecipientUID={props.careRecipientUID}
                  careAssessmentUID={null}
                  careAssessmentRow={null}
                  uiStyle={
                    uiStyle === "SummaryView" ? "SummaryStyle" : "SummaryDetails"
                  }
                  riskType={RAFRiskRegisterType.Risk}
                  // paddingClass="p-0"
                  showAddBtn={uiStyle === "SummaryView" ? false : true}
                  guttersClass="gy-2"
                  cardClassName="care_recipient_care_plan_item"
                />
              </div>
            </div>
            <div className="col-12">
              <div
                onClick={() => onClickCarePlanSummaryCard(
                  { text: CareEsioEntity.CareRecipientTrigger.CollectionName, value: CareEsioEntity.CareRecipientTrigger.EntityName }
                )}
              >
                <TriggerList
                  careRecipientUID={props.careRecipientUID}
                  uiStyle={
                    uiStyle === "SummaryView" ? "SummaryStyle" : "SummaryDetails"
                  }
                  // paddingClass="p-0"
                  showAddBtn={uiStyle === "SummaryView" ? false : true}
                />
              </div>
            </div>
            <div className="col-12">
              <div
                onClick={() => onClickCarePlanSummaryCard(
                  { text: CareEsioEntity.CareInterventionSupport.CollectionName, value: CareEsioEntity.CareInterventionSupport.EntityName }
                )}
              >
                <CareInterventionSupportList
                  careRecipientUID={props.careRecipientUID}
                  careAssessmentUID={null}
                  careAssessmentRow={null}
                  uiStyle={
                    uiStyle === "SummaryView" ? "SummaryStyle" : "SummaryDetails"
                  }
                  customCardClass="bg-light-blue-3"
                  includeHeaderClass={false}
                  showEmptyState={true}
                  showAddBtn={uiStyle === "SummaryView" ? false : true}
                />
              </div>
            </div>
          </div>
        </div>
        {/* )} */}
      </div>
    );
  };

  const wellBeingFactorsContent = (uiStyle?: "SummaryView" | "DetailView") => {
    return (
      <div
        className={
          uiStyle === "SummaryView" ? "section__container pointer" : ""
        }
      >
        {/* {uiStyle === "SummaryView" && (
          <div className="col-12 mb-1">
            <span className="subtitle_3 mb-3">PERSONALITY</span>
          </div>
        )}
        {uiStyle === "SummaryView" ? (
          <CustomCardWidget cardClassName="light_border section__div profile_card_custom_card customScrollBar">
            {isNotNullAndUndefined(currentObject.WorriesUpsetsImpact) && (
              <div className="col-12 mb-2">
                <span
                  className={"body_2 ecllipseSecondLine description-text"}
                  dangerouslySetInnerHTML={{
                    __html:
                      currentObject.WorriesUpsetsImpact !== null
                        ? DOMPurify.sanitize(currentObject.WorriesUpsetsImpact)
                        : "Not set",
                  }}
                ></span>
              </div>
            )}
            <div className="mb-2">
              <CarePreferenceList
                careRecipientUID={props.careRecipientUID}
                uiStyle={
                  uiStyle === "SummaryView" ? "SummaryStyle" : "SummaryDetails"
                }
                preferenceType="Like"
                showAddBtn={uiStyle === 'SummaryView' ? false : true}
              />
            </div>
            <div>
              <CarePreferenceList
                careRecipientUID={props.careRecipientUID}
                uiStyle={
                  uiStyle === "SummaryView" ? "SummaryStyle" : "SummaryDetails"
                }
                preferenceType="Dislike"
                showAddBtn={uiStyle === 'SummaryView' ? false : true}
              />
            </div>
          </CustomCardWidget>
        ) : ( */}
        <div className="row gx-0 gy-3">
          {IsNotNullOrWhiteSpace(currentObject.preference_summary) && (
            <div className="col-12">
              <CustomCardWidget
                cardContentClassName="p-2"
                cardClassName="border-0"
              >
                <span
                  className={"body_2 description-text"}
                  dangerouslySetInnerHTML={{
                    __html:
                      currentObject.preference_summary !== null
                        ? DOMPurify.sanitize(currentObject.preference_summary)
                        : "Not set",
                  }}
                ></span>
              </CustomCardWidget>
            </div>
          )}
          {(isNotNullAndUndefined(currentObject.routines_prefs_impact) ||
            isNotNullAndUndefined(currentObject.preference_likes) ||
            isNotNullAndUndefined(currentObject.preference_dislike) ||
            isNotNullAndUndefined(currentObject.hobbies_interests_impact) ||
            isNotNullAndUndefined(currentObject.dietary_preference)) && (
              <CustomCardWidget cardContentClassName="p-md-3 p-2">
                <div className="row gx-0 gy-3">
                  {isNotNullAndUndefined(currentObject.routines_prefs_impact) && (
                    <div className="col-12">
                      <RAFDetailFieldCustom
                        field="routines_prefs_impact"
                        title="Daily Routines"
                        value={currentObject.routines_prefs_impact}
                        // valueDivClassName="col-md-7 col-xxl-9"
                        // labelDivClassName="col-md-5 col-xxl-3"
                        rowClassName="gy-2"
                        valueClassName="description-text details-value"
                        labelClassName="profile_card_details_label"
                      ></RAFDetailFieldCustom>
                    </div>
                  )}
                  {isNotNullAndUndefined(currentObject.preference_likes) && (
                    <>
                      {isNotNullAndUndefined(
                        currentObject.routines_prefs_impact
                      ) && (
                          <div className="col-12">
                            <div className="custom__card__separator custom__card__separator__light"></div>
                          </div>
                        )}
                      <div className="col-12">
                        <RAFDetailFieldCustom
                          field="preference_likes"
                          title="Likes"
                          value={currentObject.preference_likes}
                          // valueDivClassName="col-md-7 col-xxl-9"
                          // labelDivClassName="col-md-5 col-xxl-3"
                          rowClassName="gy-2"
                          valueClassName="description-text details-value"
                          labelClassName="profile_card_details_label"
                        ></RAFDetailFieldCustom>
                      </div>
                    </>
                  )}
                  {isNotNullAndUndefined(currentObject.preference_dislike) && (
                    <>
                      <div className="col-12">
                        <div className="custom__card__separator custom__card__separator__light"></div>
                      </div>
                      <div className="col-12">
                        <RAFDetailFieldCustom
                          field="preference_dislike"
                          title="Dislikes"
                          value={currentObject.preference_dislike}
                          // valueDivClassName="col-md-7 col-xxl-9"
                          // labelDivClassName="col-md-5 col-xxl-3"
                          rowClassName="gy-2"
                          valueClassName="description-text details-value"
                          labelClassName="profile_card_details_label"
                        ></RAFDetailFieldCustom>
                      </div>
                    </>
                  )}
                  {isNotNullAndUndefined(
                    currentObject.hobbies_interests_impact
                  ) && (
                      <>
                        <div className="col-12">
                          <div className="custom__card__separator custom__card__separator__light"></div>
                        </div>
                        <div className="col-12">
                          <RAFDetailFieldCustom
                            field="hobbies_interests_impact"
                            title="Hobbies and interests"
                            value={currentObject.hobbies_interests_impact}
                            // valueDivClassName="col-md-7 col-xxl-9"
                            // labelDivClassName="col-md-5 col-xxl-3"
                            rowClassName="gy-2"
                            valueClassName="description-text details-value"
                            labelClassName="profile_card_details_label"
                          ></RAFDetailFieldCustom>
                        </div>
                      </>
                    )}
                  {isNotNullAndUndefined(currentObject.dietary_preference) && (
                    <>
                      <div className="col-12">
                        <div className="custom__card__separator custom__card__separator__light"></div>
                      </div>
                      <div className="col-12">
                        <RAFDetailFieldCustom
                          field="dietary_preference"
                          title="Dietary Preference"
                          value={currentObject.dietary_preference}
                          // valueDivClassName="col-md-7 col-xxl-9"
                          // labelDivClassName="col-md-5 col-xxl-3"
                          rowClassName="gy-2"
                          valueClassName="description-text details-value"
                          labelClassName="profile_card_details_label"
                        ></RAFDetailFieldCustom>
                      </div>
                    </>
                  )}
                </div>
              </CustomCardWidget>
            )}

          {false && (
            <div className="col-12 ">
              <CarePreferenceList
                careRecipientUID={props.careRecipientUID}
                uiStyle={
                  uiStyle === "SummaryView" ? "SummaryStyle" : "SummaryDetails"
                }
                preferenceType="Like"
                // paddingClass="p-0"
                showAddBtn={uiStyle === "SummaryView" ? false : true}
              />
            </div>
          )}
          {false && (
            <div className="col-12">
              <CarePreferenceList
                careRecipientUID={props.careRecipientUID}
                uiStyle={
                  uiStyle === "SummaryView" ? "SummaryStyle" : "SummaryDetails"
                }
                preferenceType="Dislike"
                // paddingClass="p-0"
                showAddBtn={uiStyle === "SummaryView" ? false : true}
              />
            </div>
          )}
          <div className="col-12"></div>
        </div>
        {/* )} */}
      </div>
    );
  };

  const clientNoteContent = () => {
    return (
      <div style={{ cursor: "pointer" }}>
        {/* <div className="col-12 mb-1">
          <span className="subtitle_3 mb-2">PINNED NOTES</span>
        </div>  */}
        <ClientProfileNoteList
          careRecipientUID={props.careRecipientUID}
          careRecipientName={props.careRecipientName}
          relatedToType={relatedToType}
          viewAllClick={() => onTabSelected(
            {
              text: 'Activities',
              value: RAFEntityName.Task,
              iconCss: "fas fa-clock",
              permissionName: PermissionConstants.TaskRead,
            },
            RAFTaskType.Note
          )}
        // {...(BrowserIsDevice ? { viewMode: "Horizontal" } : {})}
        />
      </div>
    );
  };

  const shiftSummaryContent = () => {
    return (
      <RAFPermissionsRender permissionNames={[
        ServiceTransactionPermissionConstants.ServiceTransactionRead,
        ServiceTransactionPermissionConstants.ServiceTransactionMyShifts
      ]
      }>
        <div className="col-12">
          <div className="row gy-2 gx-0">
            <div className="col-12">
              {/* <span className="subtitle_2 regular">SHIFT SUMMARY</span> */}
              <span className="header_6">Shift Summary</span>
            </div>
            <div className="col-12">
              <ServiceTransactionSummary
                careRecipientUID={props.careRecipientUID}
                careRecipientName={props.careRecipientName}
                careRecipientRow={currentObject}
                onServiceTransactionClicked={props.onServiceTransactionClicked}
              />
            </div>
          </div>
        </div>
      </RAFPermissionsRender>
    );
  };

  const timesheetSummaryContent = () => {
    const careRecipientName = isNotNullAndUndefined(currentObject)
      ? isNotNullAndUndefined(currentObject.recipient_name)
        ? currentObject.recipient_name
        : joinStringArray([
          currentObject.first_name,
          currentObject.middle_name,
          currentObject.last_name,
        ])
      : "";
    return (
      <div className="col-12">
        <div className="row gy-2 gx-0">
          <div className="col-12">
            <span className="header_6">Timesheet & Invoice</span>
            {/* <span className="subtitle_2 regular">TIMESHEET & INVOICE</span> */}
          </div>
          <div className="col-12">
            <ServiceTimesheetSummary
              careRecipientUID={props.careRecipientUID}
              careRecipientName={careRecipientName}
            />
          </div>
        </div>
      </div>
    );
  };

  //show key care details start
  const showKeyCareDetailsContent = () => {
    setState({ showKeyCareDetails: true });
  };

  const keyCareDetailsDialogContent = () => {
    if (state.showKeyCareDetails) {
      return (
        <div className="section__container">
          <div
            className={`section__div section__div__maxHeight p-2 p-md-3 customScrollBar`}
          >
            {keyCareDetailsContent("DetailView")}
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const closeKeyCareDetailsDialog = async () => {
    await RafClearDialogContent(keyCareDetailsDialogRef);
    setState({ showKeyCareDetails: false });
  };

  //show key care details end

  //show health conditions details start
  const showHealthConditionDetailsContent = () => {
    setState({ showHealthConditionDetails: true });
  };

  const healthConditionDetailsDialogContent = () => {
    if (state.showHealthConditionDetails) {
      return (
        <div className="section__container">
          <div
            className={`section__div section__div__maxHeight p-2 p-md-3 customScrollBar`}
          >
            {healthConditionContent("DetailView")}
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const closeHealthConditionDetailsDialog = async () => {
    await RafClearDialogContent(healthconditionDetailsDialogRef);
    setState({ showHealthConditionDetails: false });
  };

  //show health condition care details end

  //show personality details start
  const showPersonalityDetailsContent = () => {
    setState({ showPersonalityDetails: true });
  };

  const personalityDetailsDialogContent = () => {
    if (state.showPersonalityDetails) {
      return (
        <div className="section__container">
          <div
            className={`section__div section__div__maxHeight p-2 p-md-3 customScrollBar`}
          >
            {wellBeingFactorsContent("DetailView")}
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const closePersonalityDetailsDialog = async () => {
    await RafClearDialogContent(personalityDetailsDialogRef);
    setState({ showPersonalityDetails: false });
  };

  //show personality details end

  //manage carePlanContent start
  const onClickManageCarePlan = () => {
    setState({ showManageCarePlanContent: true });
  };

  function manageCarePlanContent() {
    if (state.showManageCarePlanContent === true) {
      if (isNotNullAndUndefined(state.activeCarePlan)) {
        return (
          <RAFEdit
            objectUID={state.activeCarePlan.UID}
            onSave={refreshOnManageCare.bind(this)}
            onClose={closeManageCareDialog.bind(this)}
            //onDelete={onClickDeleteCarePlan.bind(this)}
            moduleName={carePlanModule}
            isActive
            {...props}
          />
        );
      } else {
        const carePlanTitle =
          currentObject.recipient_name ??
          joinStringArray([
            currentObject.first_name,
            currentObject.middle_name,
            currentObject.last_name,
          ]);
        let initialValues = {};
        if (isNotNullAndUndefined(currentObject)) {
          initialValues[
            "Title"
          ] = `${carePlanTitle}'s ${CareEsioEntity.CarePlan.DisplayName}`;
          initialValues["CareRecipientUID"] = currentObject.UID;
          initialValues["CareRecipient"] = carePlanTitle;
        }
        return (
          <RAFCreate
            initialValues={initialValues}
            onSave={refreshOnManageCare.bind(this)}
            onClose={closeManageCareDialog.bind(this)}
            isActive
            moduleName={carePlanModule}
            {...props}
          />
        );
      }
    } else {
      return <div></div>;
    }
  }

  const refreshOnManageCare = () => {
    refresh();
  };

  const closeManageCareDialog = async () => {
    await RafClearDialogContent(carePlanDialogRef);
    setState({ showManageCarePlanContent: false });
  };

  const carePlanCustomTitle = () => {
    return (
      <div className="d-flex align-items-center">
        {isNotNullAndUndefined(state.activeCarePlan) && (
          <>
            <RAFDetailsValueWithSeparator>
              {/* {isNotNullAndUndefined(state.activeCarePlan["StartDate"]) && (
                <RAFDetailFieldCustom
                  title="Start Date"
                  value={state.activeCarePlan["StartDate"]}
                  rowClassName="gx-2 align-items-baseline"
                  labelDivClassName="col-auto"
                  valueDivClassName="col"
                  labelClassName={BrowserIsDevice ? "body_3" : "body_2"}
                  valueClassName={BrowserIsDevice ? "subtitle_3" : "subtitle_2"}
                  fieldFormat={{
                    type: RAFDataType.Date,
                    format: MomentFormats.DATE,
                  }}
                />
              )} */}
              {/* {isNotNullAndUndefined(state.activeCarePlan["ReviewDate"]) && ( */}
              <RAFDetailFieldCustom
                title="Review Date :"
                value={state.activeCarePlan["ReviewDate"]}
                rowClassName="gx-2 align-items-baseline"
                labelDivClassName="col-auto"
                valueDivClassName="col"
                labelClassName={BrowserIsDevice ? "body_3" : "body_2"}
                valueClassName={
                  BrowserIsDevice ? "subtitle_3" : "subtitle_2 semi_bold"
                }
                fieldFormat={{
                  type: RAFDataType.Date,
                  format: MomentFormats.DATE,
                }}
              />
              {/* )} */}
            </RAFDetailsValueWithSeparator>
            {createCarePlanSectionBtn()}
          </>
        )}
      </div>
    );
  };

  const createCarePlanSectionBtn = () => {
    return (
      <div className="ms-2" id="divHeaderButtons">
        <RAFPermissionRender
          permissionName={
            CareRecipientPermissionConstants.CareRecipientManageCarePlan
          }
        >
          <>
            {/* <div className="col-auto d-none d-md-block">
              <RAFButtonComponent
                type="button"
                className="custom-button-sm e-outline"
                action={RAFButtonConstant.Edit}
                onClick={() => onClickManageCarePlan()}
                idString={state.activeCarePlan.UID}
              />
            </div> */}
            <div className="">
              <RAFButtonComponent
                type="button"
                className="custom-button-sm btn_brand_primary transparent"
                action={RAFButtonConstant.Edit}
                onClick={() => onClickManageCarePlan()}
                iconBtn
                idString={`${state.activeCarePlan.UID}__responsive`}
              />
            </div>
          </>
        </RAFPermissionRender>
      </div>
    );
  };

  //manage carePlanContent end

  const clientAlertMessageContent = () => {
    return (
      <div className="col-12">
        <CustomCardWidget
          cardClassName="surface_state_warning_light border-0"
          cardContentClassName="p-2 px-3"
        >
          <div className="subtitle_2 d-flex align-items-center">
            <i className="pe-2 fas fa-circle-info content_state_warning_base line_height_inherit"></i>
            <span className="subtitle_2">
              The client funds is low.Let's contact them to renew it.
            </span>
          </div>
        </CustomCardWidget>
      </div>
    );
  };

  return (
    <div
      className={
        isNotNullAndUndefined(props.paddingClass) ? props.paddingClass : "p-3"
      }
    >
      <div className="row gy-4 gx-0">
        {showTimeSheetAndShiftSummary && (
          <div className="col-12">
            <div className="row gy-3 gx-0">
              {/* {!BrowserIsDevice && clientAlertMessageContent()} */}
              {shiftSummaryContent()}
              {isAdmin && (
                timesheetSummaryContent()
              )}
            </div>
          </div>
        )}
        {/* {BrowserIsDevice ? <div className="col-12">
          <CustomCardWidget
            cardClassName="surface_brand_primary_light"
            cardContentClassName="p-2-5"
            onClick={() =>
              onSelectedRecipientRightContent(
                CareEsioEntity.CarePlan.EntityName
              )
            }
          >
            <div className="row gx-2 align-items-center">
              <div className="col">
                <div className="row gx-2 align-items-center">
                  <div className="col-auto">
                    <i className="fad fa-hand-holding-heart fa-md pe-2 content_brand_primary_base"></i>
                  </div>
                  <div className="col">
                    <div style={{ marginBottom: '2px' }}>
                      <span className="subtitle_1">Care Plan</span>
                    </div>
                    <div>
                      <span className="body_2">
                        Manage your care plan here
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-auto">
                <RAFButtonComponent
                  btnContent="View Details"
                  iconCss="fas fa-arrow-right-long fa-md pe-2 content_brand_primary_base"
                  onClick={() =>
                    onSelectedRecipientRightContent(
                      CareEsioEntity.CarePlan.EntityName
                    )
                  }
                  className="bg-transparent border-0"
                  iconBtn
                ></RAFButtonComponent>
              </div>
            </div>
          </CustomCardWidget>

        </div> : ''} */}
        {/* {BrowserIsDevice ? 
        <div className="col-12">
          <div className="col-12">
            <div className="row gx-2 align-items-center">
              <div className="col">
                <div className="row gx-2 align-items-center">
                  <div className="col-12">
                    <div style={{ marginBottom: '2px' }}>
                      <span className="header_5">Care Plan</span>
                    </div>
                  </div>
                  <div className="col-12">
                    <span className="body_2">
                      Manage your care plan here
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-auto">
                <RAFButtonComponent
                  btnContent="View Details"
                  iconCss="fas fa-arrow-right-long fa-md pe-2 content_brand_primary_base"
                  onClick={() =>
                    onSelectedRecipientRightContent(
                      CareEsioEntity.CarePlan.EntityName
                    )
                  }
                  className="bg-transparent border-0"
                  iconBtn
                ></RAFButtonComponent>
              </div>
            </div>
          </div>
        </div> : ''} */}
        <div className="col-12">
          {showClientNotesContent && (
            clientNoteContent()
          )}
          {false && <div className="row gx-3 gy-4">
            <div className={showClientNotesContent === false ? 'col-12' : BrowserIsDevice ? "col-12 order-1" : "col-8"}>
              <CustomCardWidget
                cardClassName="surface_neutral_base"
                cardContentClassName="p-md-3 p-2"
              >
                <div className="row gx-0 gy-4">
                  <div className="col-12">
                    <div className="row gx-2 align-items-center">
                      <div className="col-12">
                        <div className="row gx-2 align-items-center">
                          <div className="col">
                            <div style={{ marginBottom: "2px" }}>
                              <span className="header_5">{`${currentObject.first_name}'s Care Summary`}</span>
                            </div>
                          </div>
                          <div className="col-auto">
                            <RAFButtonComponent
                              btnContent="View Details"
                              onClick={() => onTabSelected(
                                {
                                  text: CareEsioEntity.CarePlan.CollectionName,
                                  value: CareEsioEntity.CarePlan.EntityName,
                                  iconCss: "fas fa-notes-medical",
                                  permissionName: CarePlanPermissionConstants.CarePlanRead,
                                },
                                null
                              )}
                              // onClick={() =>
                              //   onSelectedRecipientRightContent(
                              //     CareEsioEntity.CarePlan.EntityName
                              //   )
                              // }
                              isPrimary
                            ></RAFButtonComponent>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        {/* <span className="body_2">
                              Manage your care plan here
                            </span> */}
                        <RAFPermissionRender
                          permissionName={
                            CarePlanPermissionConstants.CarePlanRead
                          }
                        >
                          <div className="">
                            {state.isLoading === false ? (
                              <>
                                {carePlanCustomTitle()}
                                {state.showManageCarePlanContent && (
                                  <DialogComponent
                                    header={`${isNotNullAndUndefined(
                                      state.activeCarePlan
                                    )
                                      ? "Update"
                                      : "Add"
                                      } ${getDisplayNameByModuleName(
                                        carePlanModule,
                                        state.carePlanEntity.DisplayName
                                      )}`}
                                    showCloseIcon
                                    visible={state.showManageCarePlanContent}
                                    content={manageCarePlanContent.bind(this)}
                                    //cssClass={entity.EntityType === RAFEntityType.UserForm ? "dlg-new-style centerDialog-lg createEditForm form-center-dialog" : "rightDialog createEditForm full-height form-center-dialog"}
                                    cssClass={
                                      "rightDialog createEditForm full-height form-center-dialog dlg-new-style"
                                    }
                                    isModal
                                    target="body"
                                    closeOnEscape={false}
                                    close={closeManageCareDialog.bind(this)}
                                    id={`dlg_create_${carePlanModule}`}
                                    zIndex={1200}
                                    open={PreventFocusOnDialogOpen}
                                    ref={carePlanDialogRef}
                                  ></DialogComponent>
                                )}
                              </>
                            ) : (
                              <div className="container-fluid px-0">
                                <ACLoadingPanel loadingText="Preparing Data..." />
                              </div>
                            )}
                          </div>
                        </RAFPermissionRender>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-12">
                      <CustomCardWidget
                        cardClassName="surface_brand_primary_light"
                        cardContentClassName="p-2-5"
                        onClick={() =>
                          onSelectedRecipientRightContent(
                            CareEsioEntity.CarePlan.EntityName
                          )
                        }
                      >
                        <div className="row gx-2">
                          <div className="col">
                            <RAFDetailsValueWithSeparator rowClassName="gx-3">
                              <div className="d-flex align-items-center">
                                <i className="fad fa-hand-holding-heart fa-md pe-2 content_brand_primary_base"></i>
                                <span className="subtitle_1">Care Plan</span>
                              </div>
                              <div>
                                <span className="body_2">
                                  Manage your care plan here
                                </span>
                              </div>
                            </RAFDetailsValueWithSeparator>
                          </div>
                          <div className="col-auto">
                            <RAFButtonComponent
                              btnContent="View Details"
                              onClick={() =>
                                onSelectedRecipientRightContent(
                                  CareEsioEntity.CarePlan.EntityName
                                )
                              }
                              isPrimary
                            ></RAFButtonComponent>
                          </div>
                        </div>
                      </CustomCardWidget>
                    </div> */}
                  <div
                    className="col-12"
                  //onClick={showHealthConditionDetailsContent}
                  >
                    <div className="mb-2 d-flex align-items-center justify-content-between">
                      <div>
                        <span className="header_6">Medical History</span>
                      </div>
                      {/* <RAFButtonComponent btnContent="View Care Plan" className="link-button custom-button-sm"></RAFButtonComponent> */}
                    </div>
                    {healthConditionContent("SummaryView")}
                  </div>
                  <div
                    className="col-12 pt-2"
                  //onClick={showKeyCareDetailsContent}
                  >
                    <div className="mb-2">
                      <span className="header_6">Key Care Details</span>
                    </div>
                    {keyCareDetailsContent("SummaryView")}
                  </div>
                  <div
                    className="col-12 pt-2"
                  //onClick={showPersonalityDetailsContent}
                  >
                    <div className="mb-2">
                      <span className="header_6">Personal Preference</span>
                    </div>
                    {wellBeingFactorsContent("SummaryView")}
                  </div>
                </div>
              </CustomCardWidget>
            </div>
            {showClientNotesContent && (
              <div className={BrowserIsDevice ? "col-12" : "col-4"}>
                {clientNoteContent()}
              </div>
            )}
          </div>}
        </div>
      </div>
      {/* <div
          className="col-xxl-4 col-md-6"
          onClick={showHealthConditionDetailsContent}
        >
          {" "}
          {healthConditionContent("SummaryView")}
        </div>
        <div className="col-xxl-4 col-md-6"
         onClick={showKeyCareDetailsContent}
         >
          {keyCareDetailsContent("SummaryView")}
        </div>
        <div
          className="col-xxl-4 col-md-6"
          onClick={showPersonalityDetailsContent}
        >
          {wellBeingFactorsContent("SummaryView")}
        </div>
        <div className="col-xxl-4 col-md-6">{clientNoteContent()}</div> */}
      {state.showKeyCareDetails && (
        <DialogComponent
          header={"Key Care Details"}
          showCloseIcon
          visible={state.showKeyCareDetails}
          cssClass="rightDialog rightDialog-lg createEditForm full-height form-center-dialog dlg-new-style"
          id="care_details_dialog"
          content={keyCareDetailsDialogContent.bind(this)}
          isModal
          target="body"
          closeOnEscape={false}
          close={closeKeyCareDetailsDialog.bind(this)}
          open={PreventFocusOnDialogOpen}
          zIndex={1200}
          ref={keyCareDetailsDialogRef}
        />
      )}
      {state.showHealthConditionDetails && (
        <DialogComponent
          header={"Medical History"}
          showCloseIcon
          visible={state.showHealthConditionDetails}
          cssClass="rightDialog rightDialog-lg createEditForm full-height form-center-dialog dlg-new-style"
          id="care_details_dialog"
          content={healthConditionDetailsDialogContent.bind(this)}
          isModal
          target="body"
          closeOnEscape={false}
          close={closeHealthConditionDetailsDialog.bind(this)}
          open={PreventFocusOnDialogOpen}
          zIndex={1200}
          ref={healthconditionDetailsDialogRef}
        />
      )}
      {state.showPersonalityDetails && (
        <DialogComponent
          header={"Personality"}
          showCloseIcon
          visible={state.showPersonalityDetails}
          cssClass="rightDialog rightDialog-lg createEditForm full-height form-center-dialog dlg-new-style"
          id="care_details_dialog"
          content={personalityDetailsDialogContent.bind(this)}
          isModal
          target="body"
          closeOnEscape={false}
          close={closePersonalityDetailsDialog.bind(this)}
          open={PreventFocusOnDialogOpen}
          zIndex={1200}
          ref={personalityDetailsDialogRef}
        />
      )}
    </div>
  );
}

export default React.memo(CareProfileContentDetails);
